import React, { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { DataContext } from '../contexts/DataContext';

const Callback = () => {
    const navigate = useNavigate();
    const { setLoading, setToken, setIsLogIn } = useContext(DataContext); // Destructure setToken from DataContext
    useEffect(() => {
        setLoading(true);
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/callback`, { code })
                .then(response => {
                    const accessToken = response.data.access_token;

                    // Store the token in localStorage
                    localStorage.setItem('smkAuthToken', accessToken);
                    setToken(accessToken);
                    setIsLogIn(true);

                    // Restore the favicon to your custom icon and add a cache buster
                    const link = document.createElement('link');
                    link.rel = 'icon';
                    link.href = `/favicon.ico?${new Date().getTime()}`; // Add a timestamp to avoid caching
                    const oldLink = document.querySelector("link[rel='icon']");
                    if (oldLink) {
                        document.head.removeChild(oldLink);
                    }
                    document.head.appendChild(link);
                    navigate('/dashboard/about');
                })
                .catch(error => {
                    console.error('Error exchanging code for token', error);
                    setLoading(false);  // Stop loading in case of an error
                    navigate('/');
                });
        }
    }, []);

    return null;  // Don't render anything for the Callback component
};

export default Callback;
