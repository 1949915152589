import React, { useState, useContext, useEffect } from 'react';
import { FaPlus, FaMinus, FaTrashAlt } from 'react-icons/fa';
import './IdealKitchen.css';
import { updateDesiredQuantities } from "../api_operations/client_api";
import { DataContext } from "../contexts/DataContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPenToSquare, faPaperPlane, faTrashCan, faKitchenSet, faCirclePlus} from '@fortawesome/free-solid-svg-icons';

const IdealKitchen = () => {
    const { setUpdatedQuantities, updatedQuantities, token, handleNullToken } = useContext(DataContext);
    const [isEditMode, setIsEditMode] = useState(false);
    const [tempQuantities, setTempQuantities] = useState({});  // Temporary state for editing
    const [newItemName, setNewItemName] = useState('');
    const [newItemQuantity, setNewItemQuantity] = useState('');
    const [duplicateItemError, setDuplicateItemError] = useState('');
    const [invalidInputError, setInvalidInputError] = useState('');

    // Sync the temp state with the original quantities when entering edit mode
    useEffect(() => {
        if (isEditMode) {
            setTempQuantities(updatedQuantities);  // Copy current quantities to temp state
        }
    }, [isEditMode]);

    // Update the desired quantities in the backend and main context state
    const handleUpdate = async () => {
        try {
            await updateDesiredQuantities(token, tempQuantities).catch((_) => handleNullToken());
            setUpdatedQuantities(tempQuantities);  // Apply the temp state to the main state
            setIsEditMode(false);  // Exit edit mode
            resetNewItemForm();    // Reset new item form
        } catch (error) {
            console.error('Error updating quantities', error);
        }
    };

    // Handel cancel, revert the temp state to original quantities
    const handleCancel = () => {
        setTempQuantities(updatedQuantities);  // Revert temp state
        setIsEditMode(false);  // Exit edit mode
        resetNewItemForm();    // Reset new item form
    };

    const resetNewItemForm = () => {
        setNewItemName('');
        setNewItemQuantity('');
        setDuplicateItemError('');
        setInvalidInputError('');
    };

    const handleNewItem = () => {
        if (!newItemName || !newItemQuantity || newItemQuantity <= 0) {
            setInvalidInputError('Please enter a valid product name and quantity.');
            return;
        }
        if (tempQuantities.hasOwnProperty(newItemName)) {
            setDuplicateItemError('This item already exists in the ideal list!');
            return;
        }
        setTempQuantities((prev) => ({ ...prev, [newItemName]: newItemQuantity }));
        resetNewItemForm();  // Clear the form inputs
    };

    // Conditionally rendering based on `isEditMode`
    const renderProducts = () => {
        const quantities = isEditMode ? tempQuantities : updatedQuantities; // Use tempQuantities in edit mode, updatedQuantities otherwise

        return Object.keys(quantities).map((product) => (
            <li key={product} className="balloon">
                <span className="product-name-bold">{product}</span>
                <span className="ideal-quantity">{quantities[product]}</span>
                {isEditMode && (
                    <div className="edit-controls">
                        <button className="edit-button-minus" onClick={() => setTempQuantities((prev) => ({ ...prev, [product]: Math.max(prev[product] - 1, 0) }))}>
                            <FaMinus />
                        </button>
                        <button className="edit-button-plus" onClick={() => setTempQuantities((prev) => ({ ...prev, [product]: prev[product] + 1 }))}>
                            <FaPlus />
                        </button>
                        <button className="remove-button" onClick={() => setTempQuantities((prev) => {
                            const updated = { ...prev };
                            delete updated[product];
                            return updated;
                        })}>
                            <FaTrashAlt />
                        </button>
                    </div>
                )}
            </li>
        ));
    };

    return (
        <div className="ideal-section">
            <div className="ideal-header">
                <h2><FontAwesomeIcon icon={faKitchenSet} /> My Ideal Kitchen</h2>
                <div className="edit-buttons">
                    <button className="edit-button" onClick={() => setIsEditMode(!isEditMode)}>
                        {isEditMode ? <FontAwesomeIcon icon={faTrashCan} onClick={handleCancel}/> : <FontAwesomeIcon icon={faPenToSquare} />}
                    </button>
                    {isEditMode && (
                        <button className="update-button-ik" onClick={handleUpdate}><FontAwesomeIcon icon={faPaperPlane} /></button>
                    )}
                </div>
            </div>
            <ul className="ideal-list">
                {renderProducts()}  {/* Render the products based on mode */}
                {isEditMode && (
                    <li className="add-new-item">
                        <span className="add-new-item-title">Add new item:</span>
                        <input type="text" value={newItemName} onChange={(e) => setNewItemName(e.target.value)} placeholder="Item Name" />
                        <input type="number" value={newItemQuantity} onChange={(e) => setNewItemQuantity(Number(e.target.value))} placeholder="Quantity" />
                        <button className="add-item-button" onClick={handleNewItem}><FontAwesomeIcon icon={faCirclePlus}/></button>
                        {(duplicateItemError || invalidInputError) && (
                            <p className="error-message">{duplicateItemError || invalidInputError}</p>
                        )}
                    </li>
                )}
            </ul>
        </div>
    );
};

export default IdealKitchen;
