import './About.css';

const About = () => {
    return (
        <div className="about-section">
            <h2>Welcome to SmartKitchen</h2>
            <p>SmartKitchen helps you keep track of your kitchen inventory, ensuring you never run out of the
                ingredients you need.</p>
            <p>Manage your stock levels, monitor desired quantities, and optimize your kitchen like a pro.</p>
        </div>
    );
};

export default About;
