import {createContext, useState, useEffect} from 'react';
import {fetchDesiredQuantities, fetchPendingPictures, fetchProducts, fetchUser} from "../api_operations/client_api";
import {useLocation, useNavigate} from 'react-router-dom';

export const DataContext = createContext();

export const DataProvider = ({ children, isLoggedIn,setIsLogIn,setLoading}) => {
    const [products, setProducts] = useState([]);
    const [updatedQuantities, setUpdatedQuantities] = useState({});
    const [token, setToken] = useState(null);
    const [userData, setUserData] = useState({});
    const [periodicFetchStatus, setPeriodicFetchStatus] = useState(false);
    const [initialFetchStatus, setInitialFetchStatus] = useState(false);
    const [images, setImages] = useState([]);
    // Get the current route location
    const location = useLocation();
    const navigate = useNavigate()
    //try to get the token from the local storage
    useEffect(() => {
        if (token == null && location.pathname !== "/callback"){
            const retrievedToken = localStorage.getItem('smkAuthToken');
            if (retrievedToken) {
                setLoading(true);
                setToken(retrievedToken);
                setIsLogIn(true);
                initialFetch().then(_=> console.log("initial fetch done"))
            }
            else {
                handleNullToken()
            }
        }

    }, [token]);

    const handleNullToken = () => {
        setToken(null)
        localStorage.removeItem('smkAuthToken')
        console.log("token is set to null since error")
        navigate("/")
        setLoading(false)
    }

    const initialFetch = async () => {
        if (token) {
            try {
                setLoading(true); // Start loading when fetching data
                const productsData = await fetchProducts(token);
                setProducts(productsData);

                const desiredQuantities = await fetchDesiredQuantities(token);
                setUpdatedQuantities(desiredQuantities);

                const userData = await fetchUser(token);
                setUserData(userData);

                const imagesData = await fetchPendingPictures(token);
                setImages(imagesData);

                setLoading(false);  // Stop loading after successful data fetch
            } catch (error) {
                setLoading(false);  // Stop loading in case of error
                handleNullToken();
            }
        }
    };

    // Function to fetch periodic data
    const fetchData = () => {
        // Ensure data is fetched only if not on the "/" route
        if (location.pathname !== "/") {
            if (token) {
                setPeriodicFetchStatus(true)
                fetchProducts(token).then((r) => setProducts(r)).catch((_) => handleNullToken());
                fetchDesiredQuantities(token).then((r) => {setUpdatedQuantities(r);}).catch((_) => handleNullToken());
                setPeriodicFetchStatus(false)

            } else {
                setPeriodicFetchStatus(false)
                console.error("No access token found");
                handleNullToken()
            }
        }
    };

    // Fetch all data when the component mounts and `isLoggedIn` changes
    useEffect(() => {

        if (isLoggedIn ) {
            setInitialFetchStatus(true)
            initialFetch();
            setInitialFetchStatus(false)
        }
    }, [token]);

    useEffect(() => {
        const interval = setInterval(fetchData, 200 * 60);
        return () => clearInterval(interval);
    }, [token, location.pathname]);

    return (
        <DataContext.Provider value={{ products, setProducts, updatedQuantities, setUpdatedQuantities,
            setToken ,token,userData,setIsLogIn,isLoggedIn,setLoading,handleNullToken, periodicFetchStatus, initialFetchStatus,
        images,setImages}}>
            {children}
        </DataContext.Provider>
    );
};
