import React, {useContext, useEffect, useState} from 'react';
import './Recipe.css';
import {DataContext} from "../contexts/DataContext";
import {fetchRecipe} from "../api_operations/client_api";
import {faBookOpen, faClock, faShoePrints, faCookieBite} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Recipe = () => {
    const {token,handleNullToken} = useContext(DataContext);
    const [recipe, setRecipe] = useState(null);
    const [fetchingRecipe, setFetchingRecipe] = useState(false);
    const [error, setError] = useState(false);

    const checkValidRecipe = () => {
        if (!recipe || !recipe['recipe_name']|| !recipe['estimated_time'] || !recipe['ingredients_used']
            || !recipe['steps']) {
            setRecipe(null);
            setError(true);
        }
    }
    const loadRecipe = async () => {
        setFetchingRecipe(true);
        setError(false);
        await fetchRecipe(token).then((r) => setRecipe(r)).catch((_) => {
            setError(true)
            handleNullToken()
        });
        setFetchingRecipe(false);
    };

    useEffect(() => {
        if (recipe) {
            checkValidRecipe();
        }
    } , [recipe]);

    return (
        <div className="recipe-section">
            <h2>Recipe of the Day <FontAwesomeIcon icon={faBookOpen} /></h2>
            {!recipe && <>
                <p>Click the button below to get the latest recipe, including ingredients, steps, and more!</p>
                <button onClick={loadRecipe} disabled={fetchingRecipe}>
                    {fetchingRecipe ? 'Fetching Recipe...' : 'Get Recipe'}
                </button>
            </>
            }

            {error && <p className="error">A recipe could not been fetch </p>}

            {recipe && (
                <div className="recipe-details">
                    <h3>{recipe.recipe_name}</h3>
                    <p><strong><FontAwesomeIcon icon={faClock} /> Estimated Time:</strong> {recipe.estimated_time}</p>
                    <h4>Ingredients:</h4>
                    <ol>
                        {Object.entries(recipe.ingredients_used).map(([ingredient, quantity], index) => (
                            <span key={index} className="recipe-ingredient-wrapper">
                                <FontAwesomeIcon className="bowl" icon={faCookieBite} />
                                <div key={index} className="recipe-single-ingredient">{ingredient} - {quantity}</div>
                            </span>

                        ))}
                    </ol>
                    <h4>Steps:</h4>
                    <ol>
                        {recipe.steps.map((step, index) => (
                            <span key={index} className="recipe-step-wrapper">
                                <FontAwesomeIcon className="shoe" icon={faShoePrints} />
                                <div className="recipe-single-step">{step}</div>
                            </span>

                        ))}
                    </ol>
                </div>
            )}
        </div>
    );
};

export default Recipe;
