import axios from "axios";

const fetchProducts = async (token) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/products`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data
    } catch (error) {
        console.error('Error fetching products', error);
        throw new Error('Invalid token');
    }
};

const fetchDesiredQuantities = async (token) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/desired-quantities`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data
    } catch (error) {
        console.error('Error fetching desired quantities', error);
        throw new Error('Invalid token');
    }
};

const updateDesiredQuantities = async (token, updatedQuantities) => {
    try {
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/update-desired-quantities`, updatedQuantities, {
            headers: { Authorization: `Bearer ${token}` },
        });
    } catch (error) {
        console.error('Error updating quantities', error);
        throw new Error('Invalid token');
    }
}

const fetchImageData = async (token, imageId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/image-data`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { image_id: imageId }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching image data', error);
        throw new Error('Invalid token');
    }
};

const fetchUser = async (token) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/userinfo`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data
    } catch (error) {
        console.error('Error fetching user data', error);
        throw new Error('Invalid token');
    }
}

const updateImage = async (token, pictureType, formData) => {
    try {
        const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/node/picture?picture_type=${pictureType}`, formData, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating picture:', error);
        throw new Error('Invalid token');
    }
}

const updateUserQuantities = async (token, updatedQuantities) => {
    try {
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/update-products`, updatedQuantities, {
            headers: { Authorization: `Bearer ${token}` },
        });
    } catch (error) {
        console.error('Error updating quantities', error);
        throw new Error('Invalid token');
    }
}

const fetchRecipe = async (token) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/recipe`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data
    } catch (error) {
        console.error('Error fetching recipe', error);
        throw new Error('Invalid token');
    }
}

const fetchPendingPictures = async (token) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/pending-pictures`, {
            headers: { Authorization: `Bearer ${token}` }
        });
        return response.data
    } catch (error) {
        console.error('Error fetching pending pictures', error);
        throw new Error('Invalid token');
    }
}

const updateImageFromPending = async (token, imageId, label) => {
    try {
        await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/update-from-pending`,
            null,
            {
                headers: { Authorization: `Bearer ${token}` },
                params: { image_id: imageId, label }
            });
    } catch (error) {
        console.error('Error updating image from pending', error);
        throw new Error('Invalid token');
    }
};


const deletePendingImage = async (token, imageId) => {
    try {
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/delete-pending`, {
            headers: { Authorization: `Bearer ${token}` },
            params: { image_id: imageId }
        });
    } catch (error) {
        console.error('Error deleting pending image', error);
        throw new Error('Invalid token');
    }
}


export {fetchRecipe,fetchProducts, fetchDesiredQuantities ,updateDesiredQuantities, fetchImageData, fetchUser,
    updateImage,updateUserQuantities,fetchPendingPictures,updateImageFromPending,deletePendingImage};

