import React, { useState} from 'react';
import {BrowserRouter as Router, Route, Routes,Navigate} from 'react-router-dom';
import Login from './pages/Login';
import Dashboard from './components/Dashboard';
import About from './pages/About';
import Inventory from './pages/Inventory';
import IdealKitchen from './pages/IdealKitchen';
import Station from "./pages/Station";
import PrivateRoute from './utils/PrivateRoute';
import Callback from './utils/Callback';
import {DataProvider} from "./contexts/DataContext";
import Loading from "./components/Loading";
import Recipe from "./pages/Recipe";
import LabelPage from "./pages/LabelPage";

function App() {
    const [isLoggedIn, setIsLogIn] = useState(false);
    const [loading, setLoading] = useState(false);

    return (
        <Router>
            <DataProvider isLoggedIn={isLoggedIn} setIsLogIn={setIsLogIn} setLoading={setLoading}>
                {loading ? <Loading /> : (
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/callback" element={<Callback />} />

                        {/* Default dashboard route */}
                        <Route path="/dashboard" element={<PrivateRoute element={<Dashboard />} />}>
                            <Route path="about" element={<About />} />
                            <Route path="inventory" element={<Inventory />} />
                            <Route path="ideal" element={<IdealKitchen />} />
                            <Route path="unlabeled" element={<LabelPage />} />
                            <Route path="recipe" element={<Recipe />} />
                            <Route path="station" element={<Station />} />

                            {/* Redirect any non-existing route within /dashboard to /dashboard/about */}
                            <Route path="*" element={<Navigate to="about" />} />
                        </Route>

                        {/* Redirect any non-existing route to /dashboard/about */}
                        <Route path="*" element={<Navigate to="/dashboard/about" />} />
                    </Routes>
                )}
            </DataProvider>
        </Router>
    );
}


export default App;
