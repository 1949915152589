import React, {useContext, useState, useEffect} from 'react';
import {Link, Outlet, useNavigate} from 'react-router-dom';
import './Dashboard.css';
import symphonyImage from '../assets/symphony.jpg';
import {DataContext} from "../contexts/DataContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen} from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
    const {setToken, userData, setIsLogIn, handleNullToken} = useContext(DataContext);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();

    const handleLogout = () => {
        setToken(null);
        setIsLogIn(false);
        localStorage.removeItem('smkAuthToken');
        handleNullToken();
        navigate('/');
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    const handleClickOutside = (event) => {
        if (isMenuOpen && !event.target.closest('.sidebar') && !event.target.closest('.hamburger')) {
            closeMenu();
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isMenuOpen]);

    return (
        <div className="dashboard">
            <nav className="navbar">
                <div className="navbar-left">
                    <div className="navbar-brand">
                        <button className="hamburger" onClick={toggleMenu}>
                            &#9776; {/* Hamburger icon */}
                        </button>
                        <img src={symphonyImage} alt="Symphony Logo" className="navbar-logo"/>
                        <h1 className="navbar-title">My Smart Kitchen</h1>
                    </div>
                </div>

                {userData && (
                    <div className="navbar-user">
                        <img src={userData.user_picture} alt={userData.name} className="navbar-user-image"/>
                        <p className="navbar-user-mail">{userData.name}</p>
                        <button className="logout-button" onClick={handleLogout}>
                            <FontAwesomeIcon icon={faDoorOpen} /></button>
                    </div>
                )}
            </nav>

            <div
                className={`sidebar ${isMenuOpen ? 'open' : ''}`}
                onMouseEnter={() => setIsMenuOpen(true)}
                onMouseLeave={closeMenu}
            >
                <button className="close-button" onClick={closeMenu}>&times;</button>
                <Link to="/dashboard/about" className="sidebar-tab" onClick={closeMenu}>About Smart Kitchen</Link>
                <Link to="/dashboard/inventory" className="sidebar-tab" onClick={closeMenu}>Kitchen Inventory</Link>
                <Link to="/dashboard/ideal" className="sidebar-tab" onClick={closeMenu}>My Ideal Kitchen</Link>
                <Link to="/dashboard/recipe" className="sidebar-tab" onClick={closeMenu}>Recipe Generator</Link>
                <Link to="/dashboard/unlabeled" className="sidebar-tab" onClick={closeMenu}>Unlabeled Items</Link>
                <Link to="/dashboard/station" className="sidebar-tab" onClick={closeMenu}>Camera Station</Link>
            </div>

            <div className="content-container">
                <Outlet/>
            </div>
        </div>
    );
};

export default Dashboard;
