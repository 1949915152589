import React, { useState, useRef, useEffect, useContext } from 'react';
import './Station.css';
import { useNavigate } from "react-router-dom";
import ImagesCarousel from "../components/ImagesCarousel";
import { updateImage } from "../api_operations/client_api";
import { DataContext } from "../contexts/DataContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRotate, faCamera, faFileUpload, faPaperPlane, faTrashCan, faLockOpen, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import {compressImageFromFormData} from "../utils/ImageCompressing";

const Station = () => {
    const { token, handleNullToken } = useContext(DataContext);
    const [isAdd, setIsAdd] = useState(true);  // State for toggle button (add/remove)
    const [capturedImage, setCapturedImage] = useState(null);  // Store captured or uploaded image
    const [cameraEnabled, setCameraEnabled] = useState(false);  // State to enable/disable camera
    const [message, setMessage] = useState('');  // Success or error message
    const [StationLoading, setStationLoading] = useState(false); // State to show pending/StationLoading
    const videoRef = useRef(null);  // Reference for the video element
    const canvasRef = useRef(null);  // Reference for the canvas element
    const navigate = useNavigate();
    const [imagesIds, setImagesIds] = useState([]);
    const [facingMode, setFacingMode] = useState('environment');
    const [multipleCameras, setMultipleCameras] = useState(false);
    const [autoSend, setAutoSend] = useState(false);

    // Function to handle the toggle button (Add/Remove)
    const handleToggle = () => {
        setIsAdd(!isAdd);
    };

    const checkCameras = () => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        navigator.mediaDevices.enumerateDevices()
            .then(devices => {
                const videoInputDevices = devices.filter(device => device.kind === 'videoinput');
                if (isMobile && videoInputDevices.length < 2) {
                    setMultipleCameras(true);
                } else {
                    setMultipleCameras(videoInputDevices.length > 1);
                }
            })
            .catch(error => {
                console.error('Error accessing devices:', error);
                setMultipleCameras(false);
            });
    };

    const switchCamera = () => {
        setFacingMode((prevMode) => (prevMode === 'user' ? 'environment' : 'user'));
        enableCamera();
    };

    // useEffect hook to enable the camera when cameraEnabled state is true
    useEffect(() => {
        if (cameraEnabled && videoRef.current) {
            navigator.mediaDevices.getUserMedia({
                video: { facingMode: facingMode }
            })
                .then((stream) => {
                    videoRef.current.srcObject = stream;
                    setMessage('');
                    checkCameras();
                })
                .catch((err) => {
                    console.error('Error accessing the camera: ', err);
                    setMessage('Error accessing the camera. Please ensure camera permissions are granted.');
                });
        }
    }, [cameraEnabled, navigate, facingMode]);

    // Function to enable the camera
    const enableCamera = () => {
        setCameraEnabled(true);
        checkCameras();
    };

    // Function to capture the image from the video stream
    const captureImage= () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;

        // Get the video's aspect ratio
        const videoWidth = video.videoWidth;
        const videoHeight = video.videoHeight;

        // Set canvas dimensions to match the video stream's aspect ratio
        canvas.width = videoWidth;
        canvas.height = videoHeight;

        const context = canvas.getContext('2d');
        // Draw the video frame onto the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Set the captured image
        setCapturedImage(canvas.toDataURL('image/jpeg'));
        setMessage('');
        setAutoSend(true);
    };

    useEffect(() => {
        if (autoSend) {
            handleUpdate();
            setAutoSend(false);
        }
    }, [capturedImage]);


    // Function to handle image upload
    const handleUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setCapturedImage(reader.result);
                setMessage('');
            };
            reader.readAsDataURL(file);
        }
    };

    // Reset the camera or uploaded image
    const resetCamera = () => {
        setCapturedImage(null);
        setMessage('');
    };

    // Function to handle the update button
    const handleUpdate = async (event) => {
        console.log('Update button clicked')
        if (event) {
            event.preventDefault();
        }

        const formData = new FormData();
        setStationLoading(true); // Show StationLoading spinner

        // Convert the base64 image to a Blob object
        const byteString = atob(capturedImage.split(',')[1]);
        const mimeString = capturedImage.split(',')[0].split(':')[1].split(';')[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([intArray], { type: mimeString });
        formData.append('picture_file', blob, 'captured_image.jpg');

        const pictureType = isAdd ? 'add' : 'remove';

        const compressedImage = await compressImageFromFormData(formData, 'picture_file').catch((_) => {
            console.log('Error compressing image');
        });

        const imageData = await updateImage(token, pictureType, compressedImage).catch((_) => {
            handleNullToken();
        });

        if (imageData) {
            setImagesIds([...imagesIds, imageData.image_id]);
            setMessage('Image uploaded successfully!');
        }
        setStationLoading(false);  // Hide StationLoading spinner
    };

    return (
        <div className="station-container">


            <div className="toggle-container">
                <button className={`toggle-button ${isAdd ? 'add' : 'remove'}`} onClick={handleToggle}>
                    {isAdd ? 'Add' : 'Remove'}
                </button>
                <span className="toggle-title">Item Using Camera Station</span>
            </div>
            <div className="camera-container">
                <div className="live-video-section">
                    {!cameraEnabled ? (
                        <button className="capture-button-begin" onClick={enableCamera}>
                            <FontAwesomeIcon icon={faCamera} className="icon-spacing" />
                            <FontAwesomeIcon icon={faArrowRight} className="icon-spacing" />
                            <FontAwesomeIcon icon={faLockOpen} />
                        </button>
                    ) : (
                        <div className="video-wrapper">
                            <video ref={videoRef} autoPlay playsInline className="video-feed"></video>
                            <canvas ref={canvasRef} className="video-canvas" style={{ display: 'none' }}></canvas>
                            <div className="buttons-wrapper">
                                <button className="switch-camera-button" onClick={switchCamera} disabled={!multipleCameras}>
                                    <FontAwesomeIcon icon={faCameraRotate} />
                                </button>
                                <button className="capture-button" onClick={captureImage}>
                                    <FontAwesomeIcon icon={faCamera} />
                                </button>
                                <label htmlFor="file-upload" className="upload-button">
                                    <FontAwesomeIcon icon={faFileUpload} />
                                    <input id="file-upload" type="file" accept="image/*" onChange={handleUpload} className="hidden-file-input" />
                                </label>
                            </div>
                        </div>
                    )}
                </div>
                <div className={`preview-section ${cameraEnabled ? 'full-width' : ''}`}>
                    {cameraEnabled ? (
                        <div className="preview-section">
                            {capturedImage && cameraEnabled ? (
                                <div className="image-preview">
                                    <img src={capturedImage} alt="Captured" className="captured-image" />
                                    <div className="image-buttons">
                                        <button className="cancel-button-station" onClick={resetCamera}>
                                            <FontAwesomeIcon icon={faTrashCan} />
                                        </button>
                                        <button className="update-button-station" onClick={handleUpdate}>
                                            <FontAwesomeIcon icon={faPaperPlane} />
                                        </button>
                                    </div>
                                    {message && !StationLoading && <p className="status-message">{message}</p>}

                                    {StationLoading && <div className="loading-spinner"></div>}
                                </div>
                            ) : (
                                <div className="image-placeholder">No image captured</div>
                            )}
                        </div>
                    ) : (
                        <span></span>
                    )}
                </div>
            </div>
            <h3 className="carousel-update-title">Recent Uploads</h3>
            <ImagesCarousel token={token} imageIds={imagesIds} />
        </div>
    );
};

export default Station;
