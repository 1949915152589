import React, {useContext} from 'react';
import './Login.css';
import symphonyImage from '../assets/symphony.jpg';
import {DataContext} from "../contexts/DataContext";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";
import {faLongArrowRight } from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

function Login() {
  const {setLoading} = useContext(DataContext);

  const handleLogin = () => {
    setLoading(true);
    const queryParams = new URLSearchParams({
      client_id: GOOGLE_CLIENT_ID,
      redirect_uri: `${process.env.REACT_APP_REDIRECT_URI}`,
      response_type: 'code',
      scope: 'openid email profile https://www.googleapis.com/auth/calendar',
    });

    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${queryParams.toString()}`;  // Redirect the user to Google's login page
  };



  return (
    <div className="login-page">
      <div className="login-container">
        <img src={symphonyImage} alt="Symphony Logo" className="symphony-image" />  {/* Symphony image */}
        <h1 className="app-title">SmartKitchen</h1>
        <p className="login-prompt">Please log in to access your SmartKitchen dashboard</p>
        <button onClick={handleLogin} className="login-button"><FontAwesomeIcon icon={faGoogle} /><span>      </span><span>     </span><FontAwesomeIcon icon={faLongArrowRight} /></button>
      </div>
    </div>
  );
}

export default Login;
