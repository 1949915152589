export async function compressImageFromFormData(formData, imageFieldName, maxWidth = 400, maxHeight = 400, quality = 0.7) {
    const file = formData.get(imageFieldName);
    const img = new Image();
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
        reader.onload = (event) => {
            img.src = event.target.result;
            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.floor(height * (maxWidth / width));
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.floor(width * (maxHeight / height));
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    if (!blob) {
                        reject(new Error('Image compression failed.'));
                        return;
                    }
                    const newFormData = new FormData();
                    newFormData.append(imageFieldName, blob, 'compressed_image.jpg');

                    for (const [key, value] of formData.entries()) {
                        if (key !== imageFieldName) {
                            newFormData.append(key, value);
                        }
                    }
                    resolve(newFormData);
                }, file.type, quality);
            };

            img.onerror = () => {
                reject(new Error('Image loading failed.'));
            };
        };
        reader.onerror = () => {
            reject(new Error('Failed to read the image.'));
        };
    });
}
