import React, { useState, useEffect, useRef, useContext } from 'react';
import { fetchImageData } from "../api_operations/client_api";
import './ImagesCarousel.css';
import { DataContext } from "../contexts/DataContext";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection

const ImagesCarousel = ({ token, imageIds }) => {
    const [imagesData, setImagesData] = useState([]);
    const { handleNullToken } = useContext(DataContext);
    const latestImageRef = useRef(null);
    const queryCount = useRef({});
    const navigate = useNavigate(); // Initialize the navigate function

    useEffect(() => {
        const fetchData = async (imageId) => {
            try {
                const imageData = await fetchImageData(token, imageId).catch((_) => { handleNullToken() });
                setImagesData((prevData) => {
                    const existingImage = prevData.find((img) => img.image_id === imageId);
                    if (!existingImage || existingImage.label !== imageData.label) {
                        return [imageData,
                            ...prevData.filter((img) => img.image_id !== imageId)
                        ];
                    }
                    return prevData;
                });
            } catch (error) {
                console.error("Error fetching image data: ", error);
            }
        };

        const intervalId = setInterval(() => {
            imageIds.forEach((imageId) => {
                if (!queryCount.current[imageId]) {
                    queryCount.current[imageId] = 0;
                }
                queryCount.current[imageId] += 1;
                if (queryCount.current[imageId] < 5) {
                    const existingImage = imagesData.find((img) => img.image_id === imageId);
                    if (!existingImage || existingImage.label === "unlabeled") {
                        fetchData(imageId).then(_ => null);
                    }
                }
            });
        }, 100);

        return () => clearInterval(intervalId);
    }, [imageIds, imagesData, token]);

    // Handle image click event for "Unlabeled" images
    const handleUnlabeledClick = () => {
        navigate('/dashboard/unlabeled'); // Redirect to the unlabeled images page
    };

    return (
        <div className="carousel-container">
            {imagesData.length > 0 && (
                imagesData.map((imageData, index) => (
                    <div
                        key={imageData.image_id}
                        className={`carousel-item ${imageData.label === "unlabeled" ? 'clickable' : 'unclickable'}`} // Add 'clickable' class if the image is unlabeled
                        onClick={imageData.label === "unlabeled" ? handleUnlabeledClick : undefined} // Attach onClick event only if the image is unlabeled
                    >
                        <img
                            src={`data:image/jpeg;base64,${imageData.image}`}
                            alt={imageData.label || "Image"}
                            className="carousel-image"
                            ref={index === imagesData.length - 1 ? latestImageRef : null}
                        />
                        {(imageData.label === "unlabeled") ? (
                            <p className="image-label unlabeled">Unlabeled</p>
                        ) : (
                            (imageData.image_type === "add") ? (
                                <p className="image-label add">{imageData.label} added</p>
                            ) : (
                                <p className="image-label remove">{imageData.label} removed</p>
                            ))
                        }
                    </div>
                ))
            )}
        </div>
    );
};

export default ImagesCarousel;
