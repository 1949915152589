import React, {useContext, useEffect} from 'react';
import './LabelPage.css';
import {DataContext} from "../contexts/DataContext";
import {fetchPendingPictures, updateImageFromPending, deletePendingImage} from "../api_operations/client_api";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane, faTrashCan} from '@fortawesome/free-solid-svg-icons';

const LabelPage = ({}) => {
    const {token, handleNullToken,images,setImages} = useContext(DataContext);

    useEffect(() => {
        // Fetch pending pictures only when token is available
        if (token) {
            fetchPendingPictures(token)
                .then((data) => {
                    setImages(data);
                })
                .catch((_) => {
                    handleNullToken();
                });
        }
    }, [token]);

    const handleUpdate = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        const newLabel = images[index].label;
        setImages(updatedImages);
        updateImageFromPending(token, images[index].image_id, newLabel).then(_ => null).catch((_) => handleNullToken());
    };

    const handleRemove = (index) => {
        const updatedImages = images.filter((_, i) => i !== index);
        setImages(updatedImages);
        deletePendingImage(token, images[index].image_id).then(_ => null).catch((_) => handleNullToken());
    };

    const handleLabelChange = (index, value) => {
        const updatedImages = [...images];
        updatedImages[index].label = value;
        setImages(updatedImages);
    };

    return (
        <div className="label-page">
            <h2>Pending Images for Labeling</h2>
            <div className="scrollable-area">
                { images.length > 0 ? (
                    images.map((image, index) => (
                        <div className="image-row" key={index}>
                            <img src={`data:image/jpeg;base64,${image.image_base64}`} alt="pending"
                                 className="pending-image"/>
                            <div className="label-and-input">
                                <div
                                    className={`image-type-icon ${image.image_type === "add" ? "icon-add" : "icon-remove"}`}>
                                    {image.image_type}
                                </div>
                                <input
                                    type="text"
                                    placeholder="New label"
                                    value={image.label || ''}
                                    onChange={(e) => handleLabelChange(index, e.target.value)}
                                    className="label-input"
                                />
                            </div>
                            {/* Buttons in a single line */}
                            <div className="label-buttons">
                                <button onClick={() => handleUpdate(index)} className="send-button">
                                    <FontAwesomeIcon icon={faPaperPlane}/>
                                </button>
                                <button onClick={() => handleRemove(index)} className="cancel-button">
                                    <FontAwesomeIcon icon={faTrashCan}/>
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No images available for labeling.</p>
                )}
            </div>
        </div>
    );
};

export default LabelPage;
