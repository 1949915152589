import React, {useState, useEffect, useContext} from 'react';
import './Inventory.css';
import {DataContext} from "../contexts/DataContext";
import {updateUserQuantities} from "../api_operations/client_api";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faCheckCircle,
    faCartShopping,
    faKitchenSet,
    faPencil,
    faPaperPlane,
    faTrashCan, faCirclePlus
} from '@fortawesome/free-solid-svg-icons';
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {FaMinus, FaPlus, FaTrashAlt} from "react-icons/fa";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';

const Inventory = () => {
    const {products, setProducts, updatedQuantities, token, handleNullToken, periodicFetchStatus, initialFetchStatus} = useContext(DataContext);
    const [shoppingList, setShoppingList] = useState([]);
    const [totalDesired, setTotalDesired] = useState(0);
    const [totalCurrent, setTotalCurrent] = useState(0);
    const [itemsToBuy, setItemsToBuy] = useState(0);
    const [isEditMode, setIsEditMode] = useState(false); // New state for edit mode
    const [tempProducts, setTempProducts] = useState({}); // Store temporary changes
    const [newItemName, setNewItemName] = useState(""); // Track new item name
    const [newItemQuantity, setNewItemQuantity] = useState(1); // Track new item quantity
    const [validationError, setValidationError] = useState("");
    const [filter, setFilter] = useState(""); // New filter state

    useEffect(() => {
        setTempProducts(products); // Initialize temp products with the original products
    }, [isEditMode,]);

    const updateQuantity = (product, newQuantity) => {
        setTempProducts(prev => ({
            ...prev,
            [product]: newQuantity
        }));
    };

    const deleteTempProduct = (product) => {
        setTempProducts((prev) => {
            const updated = {...prev};
            delete updated[product];
            return updated;
        });
    };

    const handleUpdate = async () => {
        await updateUserQuantities(token, tempProducts).catch((_) => handleNullToken());
        setProducts(tempProducts); // Update the main state
        setIsEditMode(false); // Exit edit mode
    };

    const handleCancel = () => {
        setTempProducts(products); // Reset to original quantities
        setIsEditMode(false); // Exit edit mode
    };

    // Validate new item name (English letters and spaces only)
    const validateNewItemName = (name) => {
        const regex = /^[A-Za-z\s]+$/;
        return regex.test(name);
    };

    const handleAddNewItem = () => {
        if (!validateNewItemName(newItemName)) {
            setValidationError("Product name can only contain English letters and spaces.");
            return;
        }
        const formattedItemName = capitalizeFirstLetter(newItemName.trim());

        if (!newItemName || tempProducts.hasOwnProperty(formattedItemName)) {
            setValidationError("Item already exists or invalid input.");
            return;
        }
        // Add new item to tempProducts state
        setTempProducts(prev => ({...prev, [formattedItemName]: newItemQuantity}));
        setNewItemName(""); // Reset input fields
        setNewItemQuantity(1); // Reset quantity to 1
        setValidationError(""); // Clear validation error
    };

    useEffect(() => {
        // Calculate totals and the shopping list
        const totalDesiredItems = Object.values(updatedQuantities).reduce((sum, qty) => sum + (qty || 0), 0);
        const totalCurrentItems = Object.values(products).reduce((sum, qty) => sum + (qty || 0), 0);
        const shoppingItems = Object.keys(updatedQuantities).reduce((acc, product) => {
            const currentQty = products[product] || 0;
            const desiredQty = updatedQuantities[product] || 0;
            if (currentQty < desiredQty) {
                acc.push({product, quantity: desiredQty - currentQty});
            }
            return acc;
        }, []);
        setTotalDesired(totalDesiredItems);
        setTotalCurrent(totalCurrentItems);
        setItemsToBuy(shoppingItems.length);
        setShoppingList(shoppingItems);
    }, [products, updatedQuantities]);

    const handleWhatsAppShare = () => {
        const message = shoppingList.map(item => `${item.product}: Buy ${item.quantity}`).join('\n');
        const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, '_blank');
    };

    // Capitalize the first letter of a string
    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    // Filter and sort products based on the search input
    const filteredProducts = Object.keys(isEditMode ? tempProducts : products)
        .filter(product => product.toLowerCase().includes(filter.toLowerCase())) // Filter based on input
        .sort((a, b) => a.localeCompare(b)); // Sort the filtered products

    return (
        <div className="inventory-wrapper">
            <div className="inventory-section">
                <h2 className="current-kitchen-title">
                    <FontAwesomeIcon icon={faKitchenSet}/> Current Kitchen Inventory
                </h2>

                {/* Filter input */}
                <div className="filter-edit-wrapper">
                    <div className="filter-section">
                        <input
                            type="text"
                            placeholder="Product Filter"
                            value={filter}
                            onChange={(e) => setFilter(e.target.value)}
                            className="filter-input"
                        />
                    </div>

                    {/* Edit Button */}
                    <div className="edit-buttons-iv">
                        <button className="edit-button" onClick={() => setIsEditMode(!isEditMode)}>
                            {isEditMode ? <FontAwesomeIcon icon={faTrashCan} onClick={handleCancel}/> :
                                <FontAwesomeIcon icon={faPencil}/>}
                        </button>
                        {isEditMode && (
                            <button className="update-button-ik" onClick={handleUpdate}>
                                <FontAwesomeIcon icon={faPaperPlane}/>
                            </button>
                        )}
                    </div>
                </div>
                <SimpleBar style={{maxHeight: 400, padding: '18px'}}>
                    <div className="inventory-scrollable">
                        <ul className="inventory-list">
                            {(periodicFetchStatus || initialFetchStatus) && <div className="loader"></div>}
                            {filteredProducts.map(product => (
                                <li key={product}>
                                    {isEditMode ? (
                                        <>
                                            <span className="product-name">{product}</span>
                                            <div className="quantity-controls-inventory">
                                                <button className="edit-button-minus-iv"
                                                        onClick={() => updateQuantity(product, Math.max(tempProducts[product] - 1, 1))}
                                                        disabled={(tempProducts[product] || 0) <= 0}>
                                                    <FaMinus/>
                                                </button>
                                                <button className="edit-button-plus-iv"
                                                        onClick={() => updateQuantity(product, tempProducts[product] + 1)}>
                                                    <FaPlus/>
                                                </button>
                                                <button className="remove-button-iv"
                                                        onClick={() => deleteTempProduct(product)}>
                                                    <FaTrashAlt/>
                                                </button>
                                            </div>
                                            <span className="product-current">
                                                <span className="current-quantity">{tempProducts[product]}</span>
                                            </span>
                                            <span className="product-desired">
                                                /<span
                                                className="desired-quantity">{updatedQuantities[product] || 0}</span>
                                            </span>
                                        </>
                                    ) : (
                                        <>
                                            <span className="product-name">{product}</span>
                                            <span className="product-current">
                                                <span className="current-quantity">{products[product]}</span>
                                            </span>
                                            <span className="product-desired">
                                                /<span
                                                className="desired-quantity">{updatedQuantities[product] || 0}</span>
                                            </span>
                                        </>
                                    )}
                                </li>
                            ))}

                            {/* Last line for adding a new item */}
                            {isEditMode && (
                                <li key="new-item" className="add-new-item-line">
                                    <input
                                        type="text"
                                        placeholder="New item name"
                                        value={newItemName}
                                        onChange={(e) => setNewItemName(e.target.value)}
                                        className="new-item-input-iv"
                                    />
                                    <button className="add-item-button-iv" onClick={handleAddNewItem}>
                                        <FontAwesomeIcon icon={faCirclePlus}/>
                                    </button>
                                    <span className="input-error-section"></span>
                                    <div className="quantity-controls-inventory">
                                        <button className="edit-button-minus-iv"
                                                onClick={() => setNewItemQuantity(Math.max(newItemQuantity - 1, 1))}
                                                disabled={newItemQuantity <= 1}>
                                            <FaMinus/>
                                        </button>
                                        <button className="edit-button-plus-iv"
                                                onClick={() => setNewItemQuantity(newItemQuantity + 1)}>
                                            <FaPlus/>
                                        </button>
                                    </div>
                                    <span className="product-current">
                                        <span className="current-quantity">{newItemQuantity}</span>
                                    </span>
                                    <span className="product-desired">/
                                        <span className="desired-quantity">{updatedQuantities[newItemName] || 0}</span>
                                    </span>
                                    {validationError && (
                                        <div className="error-message-wrapper-iv">
                                            <p className="error-message-iv">{validationError}</p>
                                        </div>
                                    )}
                                </li>
                            )}
                        </ul>
                    </div>
                </SimpleBar>
            </div>

            <div className="inventory-summary">
                <h3><FontAwesomeIcon icon={faPencil}/> My Kitchen Summary</h3>
                <p>Items in my ideal kitchen: {totalDesired}</p>
                <p>Items at home: {totalCurrent}</p>
                <p>Types of items to buy: {itemsToBuy}</p>

                {shoppingList.length > 0 && (
                    <div className="shopping-list">
                        <h4><FontAwesomeIcon icon={faCartShopping}/> Shopping List</h4>
                        <ul>
                            {shoppingList.map((item, index) => (
                                <li key={index} className="shopping-list-item">
                                    <FontAwesomeIcon icon={faCheckCircle} className="tick-icon"/> {item.product}:
                                    Buy {item.quantity}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <button className="export-button" onClick={handleWhatsAppShare}>
                    <FontAwesomeIcon icon={faWhatsapp}/> Export Shopping List via WhatsApp
                </button>
            </div>
        </div>
    );
};

export default Inventory;
