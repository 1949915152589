import React from 'react';
import ReactDOM from 'react-dom/client'; // Updated import for createRoot
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from './App';

// Get the root DOM element
const rootElement = document.getElementById('root');

// Create a root using createRoot
const root = ReactDOM.createRoot(rootElement);

// Render your app within the root
root.render(
  <GoogleOAuthProvider clientId="385286842014-fua76q9urgpt74n0dn38sjqvtj207oue.apps.googleusercontent.com">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>
);
